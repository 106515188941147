export default [
  {
    url: '/',
    title: 'Dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
  },
  {
    url: '/profile',
    title: 'Profile',
    slug: 'page-profile',
    icon: 'UserIcon',
    route: 'profile',
  },
  {
    url: '/apps',
    title: 'Apps',
    slug: 'apps',
    icon: 'CopyIcon',
    route: 'apps',
  },
  {
    url: '/payments',
    title: 'Payments',
    slug: 'payments',
    icon: 'DollarSignIcon',
    route: 'payments',
  },
  {
    url: '/statistics',
    title: 'Statistics',
    slug: 'statistics',
    icon: 'PieChartIcon',
    route: 'statistics',
  },
  {
    title: 'Logs',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Click Log',
        route: 'click-log',
      },
      {
        title: 'Postback Log',
        route: 'postback-log',
      },
      {
        title: 'Transaction Log',
        route: 'transaction-log',
      },
    ],
  },
]
